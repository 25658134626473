import React from 'react';

const Pricing = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <h2 className="text-2xl font-semibold text-center text-gray-800 sm:text-3xl lg:text-4xl">Nos Tarifs</h2>
      <p className="mt-4 text-lg text-gray-600 text-center">
        Choisissez parmi notre gamme de services pour trouver la solution parfaite à vos besoins de nettoyage.
        Nous offrons des tarifs compétitifs sans compromettre la qualité.
      </p>

      <p className="text-center text-sm text-gray-500 mt-2 mb-8">
      Les 17% de TVA ne sont pas inclus dans les prix.
      </p>

      <div className="mt-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {/* Limpeza de bancos do carro */}
        <div className="border border-gray-200 rounded-2xl shadow-lg p-8 text-center bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h3 className="text-2xl font-semibold text-gray-800">Nettoyage de sieges</h3>
          <p className="mt-3 text-gray-500">Nettoyage spécialisé des sièges du véhicule.</p>
          <p className="mt-6 text-4xl font-bold text-blue-600">40 €</p>
        </div>

        {/* Limpeza de interiores */}
        <div className="border border-gray-200 rounded-2xl shadow-lg p-8 text-center bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h3 className="text-2xl font-semibold text-gray-800">Nettoyage Intérieur</h3>
          <p className="mt-3 text-gray-500">Nettoyage général de l'intérieur du véhicule.</p>
          <p className="mt-6 text-4xl font-bold text-blue-600">50 €</p>
        </div>

        {/* Polimento de faróis */}
        <div className="border border-gray-200 rounded-2xl shadow-lg p-8 text-center bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h3 className="text-2xl font-semibold text-gray-800">Polissage des Phares</h3>
          <p className="mt-3 text-gray-500">Restauration et polissage des phares du véhicule.</p>
          <p className="mt-6 text-4xl font-bold text-blue-600">80 €</p>
        </div>

        {/* Limpeza de interior completa */}
        <div className="border border-gray-200 rounded-2xl shadow-lg p-8 text-center bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h3 className="text-2xl font-semibold text-gray-800">Nettoyage intérieur complet</h3>
          <p className="mt-3 text-gray-500">Le nettoyage complet comprend: Le nettoyage des vitres de la voiture, le nettoyage des sièges de voiture, le nettoyage du plastique de la voiture et nettoyage des tapis de voiture</p>
          <p className="mt-6 text-4xl font-bold text-blue-600">90 €</p>
        </div>

        {/* Lavagem do motor */}
        <div className="border border-gray-200 rounded-2xl shadow-lg p-8 text-center bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h3 className="text-2xl font-semibold text-gray-800">Lavage de Moteur</h3>
          <p className="mt-3 text-gray-500">Nettoyage en profondeur du moteur du véhicule.</p>
          <p className="mt-6 text-4xl font-bold text-blue-600">120 €</p>
        </div>

        {/* Limpeza de transportes Públicos */}
        <div className="border border-gray-200 rounded-2xl shadow-lg p-8 text-center bg-white hover:bg-blue-50 transition duration-300 ease-in-out transform hover:-translate-y-1">
          <h3 className="text-2xl font-semibold text-gray-800">Nettoyage des Transports Publics</h3>
          <p className="mt-3 text-gray-500">Nettoyage professionnel pour les transports publics, prix à convenir avec le client.</p>
          <p className="mt-6 text-4xl font-bold text-blue-600">Prix à déterminer</p>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
