// src/App.js
import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Hero from './components/Hero';
import Services from './pages/Services';
import Pricing from './pages/Pricing';
import useIntersectionObserver from './hooks/useIntersectionObserver';

function App() {
  // Setup intersection observers for each section
  const [heroRef, isHeroVisible] = useIntersectionObserver({ threshold: 0.2 });
  const [servicesRef, isServicesVisible] = useIntersectionObserver({ threshold: 0.2 });
  const [pricingRef, isPricingVisible] = useIntersectionObserver({ threshold: 0.2 });

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <div className="flex-grow">
        <section
          id="hero"
          className={`min-h-screen pt-16 transition-opacity duration-700 ease-out ${isHeroVisible ? 'opacity-100' : 'opacity-0'
            }`}
          ref={heroRef}
        >
          <Hero />
        </section>
        <section
          id="services"
          className={`min-h-screen bg-gray-100 pt-16 transition-opacity duration-700 ease-out ${isServicesVisible ? 'opacity-100' : 'opacity-0'
            }`}
          ref={servicesRef}
        >
          <Services />
        </section>
        <section
          id="pricing"
          className={`min-h-screen bg-white pt-16 transition-opacity duration-700 ease-out ${isPricingVisible ? 'opacity-100' : 'opacity-0'
            }`}
          ref={pricingRef}
        >
          <Pricing />
        </section>
      </div>
      <Footer />
    </div>
  );
}

export default App;
