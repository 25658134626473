import React, { useState, useEffect } from 'react';
import { logo } from "../assets";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faEnvelope } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const [activeLink, setActiveLink] = useState('home');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isTopBarVisible, setIsTopBarVisible] = useState(true);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setIsMobileMenuOpen(false); // Close mobile menu on link click
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsTopBarVisible(window.scrollY === 0);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={`bg-gray-900 text-white py-2 fixed top-0 left-0 right-0 z-20 transition-transform ${isTopBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faClock} className="h-5 w-5" />
            <span className="text-sm">Lundi-Samedi 9H - 4H</span>
          </div>

          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faEnvelope} className="h-5 w-5" />
            <a href="mailto:freitascleaning97@gmail.com" className="text-sm text-white hover:text-white">
              freitascleaning97@gmail.com
            </a>
          </div>

        </div>
      </div>

      {/* Navbar */}
      <nav className={`bg-white shadow-md fixed ${isTopBarVisible ? 'top-10' : 'top-0'} left-0 right-0 z-10 transition-all`}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <img src={logo} alt="Logo" className="h-8 w-8 mr-2 rounded-full" />
              <a href="/" className="text-xl font-bold text-gray-800">Industrial Freitas Clean</a>
            </div>

            {/* Mobile menu button */}
            <div className="flex lg:hidden">
              <button
                onClick={toggleMobileMenu}
                className="text-gray-800 hover:text-blue-500 focus:outline-none focus:text-blue-500"
              >
                <svg className="h-6 w-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d={isMobileMenuOpen ? 'M6 18L18 6M6 6l12 12' : 'M4 6h16M4 12h16M4 18h16'} />
                </svg>
              </button>
            </div>

            {/* Navbar links */}
            <div className="hidden lg:flex space-x-4">
              <a
                href="#hero"
                className={`text-gray-600 hover:text-blue-500 ${activeLink === 'home' ? 'text-blue-500 font-bold' : ''}`}
                onClick={() => handleLinkClick('home')}
              >
                Home
              </a>
              <a
                href="#services"
                className={`text-gray-600 hover:text-blue-500 ${activeLink === 'services' ? 'text-blue-500 font-bold' : ''}`}
                onClick={() => handleLinkClick('services')}
              >
                Services
              </a>
              <a
                href="#pricing"
                className={`text-gray-600 hover:text-blue-500 ${activeLink === 'pricing' ? 'text-blue-500 font-bold' : ''}`}
                onClick={() => handleLinkClick('pricing')}
              >
                Prix
              </a>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        {isMobileMenuOpen && (
          <div className="lg:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              <a
                href="#hero"
                className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'home' ? 'text-blue-500 font-bold' : ''}`}
                onClick={() => handleLinkClick('home')}
              >
                Home
              </a>
              <a
                href="#services"
                className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'services' ? 'text-blue-500 font-bold' : ''}`}
                onClick={() => handleLinkClick('services')}
              >
                Services
              </a>
              <a
                href="#pricing"
                className={`block px-3 py-2 rounded-md text-base font-medium text-gray-600 hover:text-blue-500 ${activeLink === 'pricing' ? 'text-blue-500 font-bold' : ''}`}
                onClick={() => handleLinkClick('pricing')}
              >
                Prix
              </a>
            </div>
          </div>
        )}
      </nav>
    </>
  );
};

export default Navbar;
