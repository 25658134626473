import React from 'react';
import { background } from '../assets';

const Hero = () => {
  return (
    <div
      className="h-screen bg-cover bg-center flex items-center justify-center"
      style={{ backgroundImage: `url(${background})` }} >

      <div className="relative text-center p-8">
        <h1 className="text-white text-5xl md:text-6xl font-bold mb-4 drop-shadow-lg">
          Industrial Freitas Clean
        </h1>
        <p className="text-white text-lg md:text-xl mb-6 drop-shadow-lg">
          Rendre votre espace propre et confortable
        </p>
      </div>
    </div>
  );
};

export default Hero;
